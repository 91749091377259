import React from 'react';

import Icon from '@atlaskit/icon';
import type { CustomGlyphProps, IconProps } from '@atlaskit/icon/types';

export const SignInGlyph = (props: CustomGlyphProps) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M18.0234 4.29985L18.1727 4.30534C19.2076 4.38169 20.0234 5.24549 20.0234 6.29985V18.2999L20.018 18.4491C19.9416 19.484 19.0778 20.2999 18.0234 20.2999H13.0234L12.9068 20.2931C12.4095 20.2354 12.0234 19.8127 12.0234 19.2999C12.0234 18.7476 12.4712 18.2999 13.0234 18.2999H18.0234V6.29985H13.0234L12.9068 6.29312C12.4095 6.23536 12.0234 5.81269 12.0234 5.29985C12.0234 4.74757 12.4712 4.29985 13.0234 4.29985H18.0234ZM16.0193 12.3935L16.0135 12.4412C16.0104 12.4655 16.006 12.4902 16.0006 12.5148L15.9929 12.5461C15.9872 12.5704 15.9801 12.5951 15.9719 12.6194L15.9543 12.6658C15.9471 12.6865 15.9382 12.7072 15.9286 12.7276C15.922 12.7394 15.9161 12.7512 15.91 12.7629C15.8993 12.7857 15.8866 12.808 15.8729 12.8299C15.8685 12.8348 15.865 12.8402 15.8615 12.8456C15.8473 12.8696 15.831 12.8926 15.8135 12.9151L15.7303 13.0092L12.7303 16.0072C12.3396 16.3976 11.7065 16.3974 11.3161 16.0067C10.9557 15.6461 10.9282 15.0789 11.2334 14.6867L11.3166 14.5925L12.6114 13.2999H5.02344C4.47115 13.2999 4.02344 12.8521 4.02344 12.2999C4.02344 11.79 4.40492 11.3693 4.898 11.3076L5.02344 11.2999H12.6094L11.3161 10.0067C10.9557 9.64611 10.9282 9.07887 11.2334 8.68668L11.3166 8.5925C11.6772 8.23214 12.2444 8.20461 12.6366 8.50976L12.7308 8.59298L15.7046 11.5677C15.801 11.6574 15.8797 11.766 15.9348 11.8876C15.9383 11.8974 15.9424 11.9067 15.9463 11.916C15.9583 11.9428 15.9685 11.9706 15.9774 11.999L15.9846 12.0253C15.9928 12.0515 15.9993 12.0786 16.0047 12.106C16.007 12.1202 16.0094 12.1339 16.0115 12.1476L16.0199 12.215C16.0216 12.2369 16.0227 12.2585 16.0232 12.2802C16.0234 12.2869 16.0234 12.2934 16.0234 12.2999L16.0224 12.3179L16.0193 12.3935Z"
		/>
	</svg>
);

export function SignInIcon(props: Omit<IconProps, 'glyph'>) {
	return <Icon {...props} glyph={SignInGlyph} />;
}
