/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import type { ReactNode } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl-next';

import { Text } from '@atlaskit/primitives';
import { B50, N20A, N800 } from '@atlaskit/theme/colors';
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { borderRadius, gridSize } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';

import type { AccountMenuProps } from '../account-menu';
import { PersonIcon } from '../icons/person';
import { ProfilePicture } from '../profile-picture';
import { SkeletonText } from '../skeleton-text';

export type AccountDetailsProps = Pick<
	AccountMenuProps,
	'testId' | 'name' | 'picture' | 'email' | 'managedBy'
>;

export const AccountDetails: React.ComponentType<AccountDetailsProps> = ({
	testId,
	name,
	picture,
	email,
	managedBy,
}) => (
	<Container data-testid={testId}>
		{/* ManagedBy can't go on top of a background since it's background may not be opaque. */}
		{managedBy && (
			<ManagedBy>
				<PersonIconWrapper>
					<PersonIcon label="" size="small" />
				</PersonIconWrapper>
				<FormattedMessage
					id="identity.account-menu.account-menu-details.managed-account"
					defaultMessage="This account is managed by <b>{managedBy}</b>"
					description="Label showing the user which organisation manages their Atlassian account"
					values={{
						b: (text: ReactNode[]) => (
							<Text as="strong" size="small" color="inherit">
								{text}
							</Text>
						),
						managedBy,
					}}
				/>
			</ManagedBy>
		)}

		<Content hasHeader={!!managedBy}>
			<Profile testId={testId} name={name} picture={picture} email={email} />
		</Content>
	</Container>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.section({
	display: 'flex',
	flexDirection: 'column',
	margin: `0 ${token('space.100', '8px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ManagedBy = styled.small({
	display: 'box',
	margin: '0px',
	padding: `${token('space.025', '2px')} ${token('space.100', '8px')}`,
	textAlign: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius()}px ${borderRadius()}px 0 0`,
	background: token('color.background.information', B50),
	color: token('color.text.subtle', '#44546F'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PersonIconWrapper = styled.span({
	marginRight: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		width: '12px',
		height: '12px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div<{ hasHeader: boolean }>(
	{
		display: 'flex',
		flexDirection: 'column',
		background: token('elevation.surface.sunken', N20A),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ hasHeader }) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: hasHeader ? `0 0 ${borderRadius()}px ${borderRadius()}px` : `${borderRadius()}px`,
	}),
);

const Profile: React.ComponentType<
	Pick<AccountDetailsProps, 'name' | 'email' | 'picture' | 'testId'>
> = ({ name, email, picture, testId }) => (
	<ProfileWrapper>
		<ProfilePicture src={picture} size={gridSize() * 8} testId={testId && `${testId}--picture`} />
		<NameAndEmail>
			<DisplayName data-testid={testId && `${testId}--name`}>
				{name || <SkeletonText length="6em" />}
			</DisplayName>
			<EmailAddress data-testid={testId && `${testId}--email`}>
				{email || <SkeletonText length="10em" />}
			</EmailAddress>
		</NameAndEmail>
	</ProfileWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProfileWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	margin: `${token('space.200', '16px')} ${token('space.200', '16px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NameAndEmail = styled.div({
	display: 'flex',
	flexDirection: 'column',
	margin: `auto 0 auto ${token('space.200', '16px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DisplayName = styled.h3({
	color: token('color.text', N800),
	margin: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmailAddress = styled.p({
	marginTop: token('space.025', '2px'),
	color: token('color.text.subtlest', '#626F86'),
	fontWeight: 400,
});
