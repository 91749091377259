import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import DropdownMenu, { DropdownItemRadio } from '@atlaskit/dropdown-menu';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { ButtonItem } from '@atlaskit/menu';
import { type ThemeColorModes, token } from '@atlaskit/tokens';

import { ThemeIcon } from '../icons/theme';

import DarkModeThumbnail from './thumbnails/dark-mode.svg';
import LightModeThumbnail from './thumbnails/light-mode.svg';
import MatchBrowserThumbnail from './thumbnails/match-browser.svg';

export { type ThemeColorModes } from '@atlaskit/tokens';

type ThemeOptionType = {
	label: JSX.Element;
	value: ThemeColorModes;
	thumbnail: string;
};

type ThemeSwitcherProps = {
	/**
	 * A `testId` prop is provided for specified elements, which is a unique
	 * string that appears as a data attribute `data-testid` in the rendered code,
	 * serving as a hook for automated tests.
	 */
	testId?: string;
	/**
	 * Event triggered when an option in the ThemeSwitcher is clicked.
	 */
	onThemeChange: (theme: ThemeColorModes) => void;
	/**
	 * The current theme enabled for the product (eg. 'dark').
	 */
	currentTheme: ThemeColorModes;
};

export const ThemeSwitcher = ({ testId, onThemeChange, currentTheme }: ThemeSwitcherProps) => {
	return (
		<DropdownMenu
			testId={testId}
			placement="auto-start"
			trigger={({ triggerRef, ...props }) => (
				<ButtonItem
					ref={triggerRef}
					onClick={(event) => event.stopPropagation()}
					iconBefore={<ThemeIcon label="" />}
					iconAfter={<ChevronRightIcon color="currentColor" label="" />}
					{...props}
				>
					<FormattedMessage
						id="identity.account-menu.theme-switcher.theme-button"
						defaultMessage="Theme"
						description="Label for button in the account menu to switch to a different theme"
					/>
				</ButtonItem>
			)}
		>
			{themeOptions.map(({ label, value, thumbnail }) => (
				<DropdownItemRadio
					testId={`${testId}--radio-${value}`}
					id={value}
					key={value}
					onClick={() => onThemeChange(value)}
					isSelected={currentTheme === value}
				>
					<RadioLabelContainer>
						<img src={thumbnail} width={64} height={48} alt="" />
						{label}
					</RadioLabelContainer>
				</DropdownItemRadio>
			))}
		</DropdownMenu>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RadioLabelContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.150', '12px'),
});

const messages = defineMessages({
	light: {
		id: 'identity.account-menu.theme-switcher.light.option',
		defaultMessage: 'Light',
		description: 'Label for the theme switcher light option',
	},
	dark: {
		id: 'identity.account-menu.theme-switcher.dark.option',
		defaultMessage: 'Dark',
		description: 'Label for the theme switcher dark option',
	},
	matchBrowser: {
		id: 'identity.account-menu.theme-switcher.match-browser.option',
		defaultMessage: 'Match browser',
		description: 'Label for the theme switcher match browser option',
	},
});

const themeOptions: readonly ThemeOptionType[] = [
	{
		label: <FormattedMessage {...messages.light} />,
		value: 'light',
		thumbnail: LightModeThumbnail,
	},
	{
		label: <FormattedMessage {...messages.dark} />,
		value: 'dark',
		thumbnail: DarkModeThumbnail,
	},
	{
		label: <FormattedMessage {...messages.matchBrowser} />,
		value: 'auto',
		thumbnail: MatchBrowserThumbnail,
	},
] as const;
